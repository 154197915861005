<template>
  <div class="pa-5">
    <div class="text-center">
      <h2 v-if="crud_state == '3'">Add User</h2>
      <h2 v-else-if="crud_state == '2'">Edit User</h2>
    </div>
    <div class="space-30"></div>
    <v-card v-if="loading">
      <v-skeleton-loader
        v-bind="$attrs"
        type="table-heading, list-item-two-line, image"
      ></v-skeleton-loader>
    </v-card>
    <v-card>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-container fluid>
          <v-row>
            <v-col>
              <v-text-field
                v-model.trim="item.name"
                label="User Name"
                :rules="[(v) => !!v || 'Name is required']"
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model.trim="item.password_hash"
                label="Password"
                type="password"
                required
                :rules="[
                  (v) => crud_state == '2' || !!v || 'Password is required',
                ]"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-switch v-model="item.status" inset label="Status"></v-switch>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model.trim="item.email"
                label="Email"
                required
                :rules="[(v) => !!v || 'Email is required']"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-select
                :items="userRoles"
                label="Select User Role"
                v-model="item.user_role"
                item-text="name"
                item-value="id"
                dense
              >
              </v-select>
            </v-col>
          </v-row>
          <v-row class="mx-1 my-2">
            <v-col>
              <v-btn
                class="ma-2"
                :loading="loading"
                :disabled="loading"
                color="success"
                @click="validate"
              >
                <v-icon small left>mdi-check</v-icon>
                Save
              </v-btn>
            </v-col>
            <v-col>
              <v-btn text class="ma-2" color="primary" @click="backToUsersList">
                Cancel
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card>

    <Snackbar
      class="mb-10"
      v-if="snackbar.show"
      :show="snackbar.show"
      :text="snackbar.text"
      :color="snackbar.color"
    ></Snackbar>
  </div>
</template>

<script>
import { crud_state } from "@/lib/constants";
import BaseService from "@/services/base.service.js";
import Snackbar from "@/components/common/Snackbar";
import { bus } from "@/main.js";

export default {
  name: "UserEdit",
  components: {
    Snackbar,
  },
  props: {
    id: String,
  },
  data() {
    return {
      item: {},
      snackbar: {},
      loading: false,
      valid: true,
      userRoles: [
        { name: "NORMAL_USER", id: 1 },
        { name: "FRANCHISEE", id: 100 },
        { name: "FRANCHISOR", id: 190 },
        { name: "FRANCHISE", id: 200 },
        { name: "UNDERWRITER", id: 300 },
        { name: "BROKER", id: 500 },
        { name: "INSURER", id: 600 },
        { name: "ADMIN", id: 1000 },
      ],
    };
  },
  computed: {
    crud_state() {
      return this.$route.query.crud_state;
    },
  },
  async created() {
    if (this.id) {
      this.item = await this.getItem(this.id);
    } else {
      this.item.status = true;
    }
  },
  methods: {
    async getItem(id) {
      this.loading = true;
      let service = new BaseService("users");
      let res = await service.get_one(id);
      this.loading = false;

      return res.data;
    },
    validate() {
      let result = this.$refs.form.validate();
      if (result) {
        this.save();
      }
    },
    backToUsersList() {
      this.$router.push("/users/list");
    },
    async save() {
      this.loading = true;
      let service = new BaseService("users");

      let res;

      if (this.crud_state == crud_state.NEW) {
        res = await service.create(this.item);
      } else {
        res = await service.patch(this.item);
      }

      this.loading = false;

      if (res.status == 401) {
        this.handleLogout();
      } else if (res.status == 200 || res.status == 201) {
        bus.$emit("edit_save", { msg: "Saved successfully", data: res.data });
        this.$router.push(`/users/list`);
      } else {
        this.snackbar = {
          show: true,
          text: res?.data?.msg
            ? res.data.msg
            : "An error occurred, could not save",
          color: `red`,
        };
        this.resetSnackbar();
      }
    },

    resetSnackbar() {
      setTimeout(() => {
        this.snackbar = {};
      }, 2000);
    },

    handleLogout() {
      this.$store.dispatch("auth/logout");
    },
  },
};
</script>
