<template>
  <div :class="!isCoverageFranchisee ? 'pa-5' : ''">
    <div class="text-center">
      <h2 v-if="crud_state == '3'">Add Client</h2>
      <h2 v-else-if="crud_state == '2'">Edit Client</h2>
    </div>
    <div class="space-30"></div>
    <v-card v-if="loading">
      <v-skeleton-loader
        v-bind="$attrs"
        type="table-heading, list-item-two-line, image"
      ></v-skeleton-loader>
    </v-card>
    <v-stepper v-else v-model="currentStep">
      <v-stepper-header>
        <v-stepper-step
          :color="isFranchisor ? franchiseData.brand_text_color : 'primary'"
          :style="isFranchisor ? franchiseData.brand_background_color : ''"
          :editable="item && item.id && stepperEnable[1] ? true : false"
          :rules="[(value) => stepsValidations.basicInfo]"
          step="1"
        >
          Basic Information
          <small
            class="d-none d-lg-flex d-xl-none"
            v-if="!stepsValidations.basicInfo"
            >Enter Client Name</small
          >
        </v-stepper-step>

        <v-stepper-step
          :color="isFranchisor ? franchiseData.brand_text_color : 'primary'"
          :style="isFranchisor ? franchiseData.brand_background_color : ''"
          :editable="item && item.id && stepperEnable[2] ? true : false"
          :rules="[(value) => stepsValidations.contact]"
          step="2"
        >
          Contact
          <small
            class="d-none d-lg-flex d-xl-none"
            v-if="!stepsValidations.contact"
            >Enter Contact Person</small
          >
        </v-stepper-step>

        <v-stepper-step
          :color="isFranchisor ? franchiseData.brand_text_color : 'primary'"
          :style="isFranchisor ? franchiseData.brand_background_color : ''"
          :editable="item && item.id && stepperEnable[3] ? true : false"
          :rules="[(value) => stepsValidations.billing]"
          step="3"
        >
          Billing Contact
          <small
            class="d-none d-lg-flex d-xl-none"
            v-if="!stepsValidations.billing"
            >Enter Billing Contact Person</small
          >
        </v-stepper-step>

        <v-stepper-step
          :color="isFranchisor ? franchiseData.brand_text_color : 'primary'"
          :style="isFranchisor ? franchiseData.brand_background_color : ''"
          :editable="item && item.id && stepperEnable[4] ? true : false"
          step="4"
        >
          Situations
        </v-stepper-step>
      </v-stepper-header>
      <v-stepper-items>
        <v-form
          v-model="stepsValidations.basicInfo"
          ref="basicInfo"
          lazy-validation
        >
          <v-stepper-content id="basic-info" class="pa-0" step="1">
            <div class="pa-10">
              <p>Client Name</p>
              <v-text-field
                class="mb-5"
                outlined
                v-model="item.name"
                :rules="[(v) => !!v || 'Client Name is required']"
                dense
                label="Enter Client Name"
              ></v-text-field>
              <!-- <v-row no-gutters>
                <v-col>
                  <v-switch
                    class="ml-4"
                    v-model="item.status"
                    inset
                    label="Status"
                  ></v-switch>
                </v-col>
              </v-row> -->
              <v-row>
                <v-col cols="12" lg="6" md="6" class="col-x-margin-0">
                  <p>Franchise</p>
                  <v-autocomplete
                    :disabled="!isUserAdmin"
                    v-model="item.franchise_id"
                    :loading="franchiseLoading"
                    :items="franchiseItems"
                    @focus="getAllFranchises()"
                    @input="getClusterSublookup()"
                    :search-input.sync="franchiseSearch"
                    item-text="name"
                    item-value="id"
                    flat
                    label="Enter Franchise"
                    autocomplete="nope"
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" lg="6" md="6" class="col-x-margin-0">
                  <p>Trading Name</p>
                  <v-text-field
                    class="mb-5"
                    outlined
                    v-model="item.trading_name"
                    dense
                    label="Enter Trading Name"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <p>Cluster Group Sub Item</p>
                  <v-autocomplete
                    v-model="item.cluster_group_sub_lookup_id"
                    :loading="clusterLoading"
                    :items="clusterItems"
                    item-text="name"
                    item-value="id"
                    outlined
                    dense
                    label="Enter Cluster Group"
                    autocomplete="nope"
                    :disabled="clusterItems.length < 1"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row class="mt-n5">
                <!-- <v-col>
                  <v-switch
                    class="ml-4"
                    v-model="item.force_new_policy_set"
                    inset
                    label="Force New Policy Set"
                  ></v-switch>
                </v-col> -->
                <v-col cols="4" v-if="isUserAdmin">
                  <v-switch
                    class="ml-4"
                    v-model="item.always_refer"
                    inset
                    label="Always Refer"
                  ></v-switch>
                </v-col>
                <v-col cols="4">
                  <p>Client Code</p>
                  <v-text-field
                    outlined
                    v-model="item.client_code"
                    dense
                    label="Enter Client Code"
                  ></v-text-field>
                </v-col>
                <v-col :cols="isUserAdmin ? '4' : '8'" class="col-x-margin-0">
                  <p>ABN Account</p>
                  <v-text-field
                    class="mb-5"
                    outlined
                    v-model="item.abn_acn"
                    :rules="[(v) => !!v || 'ABN Account is required']"
                    dense
                    label="Enter ABN Account"
                  ></v-text-field>
                </v-col>
              </v-row>

              <UserEditForm
                v-if="isDataLoaded && isUserAdmin"
                :item="item.user"
                hideuserName
              />
            </div>
            <v-footer paddless class="pa-10">
              <v-row class="justify-end ">
                <v-btn
                  :color="
                    isFranchisor
                      ? franchiseData.brand_background_color
                      : 'primary'
                  "
                  :style="
                    isFranchisor
                      ? { color: franchiseData.brand_text_color }
                      : ''
                  "
                  @click="submitCurrentStep"
                >
                  Continue
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>

                <v-btn text @click="backToFranchiseesList">
                  Cancel
                </v-btn>
              </v-row>
            </v-footer>
          </v-stepper-content>

          <v-stepper-content
            v-if="isDataLoaded"
            id="contact"
            class="pa-0"
            step="2"
          >
            <ContactForm
              class="pa-10"
              ref="contact_form"
              :isValid="stepsValidations"
              :formTitle="'Contact Information'"
              :form="item.contact ? item.contact : blankContact"
              :type="'contact'"
              isMobile
              isEmail
            />
            <div class="space-20"></div>

            <v-footer paddless class="pa-7">
              <v-row>
                <v-col :cols="isSizeSmall ? 12 : 4">
                  <v-btn
                    :block="isSizeSmall"
                    text
                    @click="navigateToPreviousStep"
                  >
                    <v-icon>mdi-chevron-left</v-icon> Back</v-btn
                  >
                </v-col>
                <v-col :cols="isSizeSmall ? 12 : 8">
                  <v-row
                    class="justify-end pt-3"
                    :class="isSizeSmall ? 'px-10' : ''"
                  >
                    <v-btn
                      :color="
                        isFranchisor
                          ? franchiseData.brand_background_color
                          : 'primary'
                      "
                      :style="
                        isFranchisor
                          ? { color: franchiseData.brand_text_color }
                          : ''
                      "
                      :block="isSizeSmall"
                      @click="submitCurrentStep"
                    >
                      Continue
                      <v-icon>mdi-chevron-right</v-icon>
                    </v-btn>

                    <v-btn
                      text
                      :block="isSizeSmall"
                      @click="backToFranchiseesList"
                    >
                      Cancel
                    </v-btn>
                  </v-row>
                </v-col>
              </v-row>
            </v-footer>
          </v-stepper-content>

          <v-stepper-content
            v-if="isDataLoaded"
            id="billing-contact"
            class="pa-0"
            step="3"
          >
            <ContactForm
              class="pa-10"
              ref="billing_form"
              :isValid="stepsValidations"
              :formTitle="'Billing Contact Details'"
              :form="item.billing_contact ? item.billing_contact : blankContact"
              :type="`billing`"
              isAddress
              isPhone
              isEmail
            />

            <div class="space-20"></div>
            <v-footer paddless class="pa-7">
              <v-row>
                <v-col :cols="isSizeSmall ? 12 : 4">
                  <v-btn
                    :block="isSizeSmall"
                    text
                    @click="navigateToPreviousStep"
                  >
                    <v-icon>mdi-chevron-left</v-icon> Back</v-btn
                  >
                </v-col>
                <v-col :cols="isSizeSmall ? 12 : 8">
                  <v-row
                    class="justify-end pt-3"
                    :class="isSizeSmall ? 'px-10' : ''"
                  >
                    <v-btn
                      :color="
                        isFranchisor
                          ? franchiseData.brand_background_color
                          : 'success'
                      "
                      :style="
                        isFranchisor
                          ? { color: franchiseData.brand_text_color }
                          : ''
                      "
                      :block="isSizeSmall"
                      @click="validate"
                    >
                      <v-icon small left>mdi-check</v-icon>

                      <template>Continue</template>
                    </v-btn>

                    <v-btn
                      text
                      :block="isSizeSmall"
                      @click="backToFranchiseesList"
                    >
                      Cancel
                    </v-btn>
                  </v-row>
                </v-col>
              </v-row>
            </v-footer>
          </v-stepper-content>

          <v-stepper-content
            v-if="isDataLoaded"
            id="situation list"
            class="pa-0"
            step="4"
          >
            <SituationsList
              class="pa-10"
              :franchiseeId="franchiseeId"
              @itemsLoaded="situationsLoaded()"
              @isSituationPresent="enableDone"
              @hideSave="disabledSave"
            />

            <span class="pa-10" style="color: red" v-if="!isDoneButtonEnabled"
              >Please add a situation to continue</span
            >

            <div class="space-50"></div>

            <v-footer paddless class="pa-7">
              <v-row>
                <v-col :cols="isSizeSmall ? 12 : 4">
                  <v-btn
                    :block="isSizeSmall"
                    text
                    @click="navigateToPreviousStep"
                  >
                    <v-icon>mdi-chevron-left</v-icon> Back</v-btn
                  >
                </v-col>
                <v-col :cols="isSizeSmall ? 12 : 8">
                  <v-row
                    class="justify-end pt-3"
                    :class="isSizeSmall ? 'px-10' : ''"
                  >
                    <v-btn
                      :color="
                        isFranchisor
                          ? franchiseData.brand_background_color
                          : 'success'
                      "
                      :style="
                        isFranchisor
                          ? { color: franchiseData.brand_text_color }
                          : ''
                      "
                      :disabled="!isDoneButtonEnabled || !enableSave"
                      class="mr-8"
                      :block="isSizeSmall"
                      @click="moveForward"
                    >
                      Save & Continue
                    </v-btn>
                  </v-row>
                </v-col>
              </v-row>
            </v-footer>
          </v-stepper-content>
        </v-form>
      </v-stepper-items>
    </v-stepper>

    <Snackbar
      class="mb-10"
      v-if="snackbar.show"
      :show="snackbar.show"
      :text="snackbar.text"
      :color="snackbar.color"
    ></Snackbar>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import UserEditForm from "@/components/common/UserEditForm";
import ContactForm from "@/components/common/contact-form/ContactForm";
import Snackbar from "@/components/common/Snackbar";
import SituationsList from "@/components/situations/SituationList";
import Contact from "@/models/contact-form";
import BaseService from "@/services/base.service.js";
import { debounce } from "@/helpers/basic_helper";
import franchiseService from "@/services/franchise.service.js";
import { crud_state } from "@/lib/constants";
import Franchisees from "@/models/franchisees.js";
import { bus } from "@/main.js";
import _ from "lodash";
import FranchiseMixin from "@/mixins/franchise-style";

export default {
  name: "FranchiseesEdit",
  components: { ContactForm, Snackbar, SituationsList, UserEditForm },
  props: {
    id: String,
    type_id: Number,
    isCoverageFranchisee: Boolean,
  },
  mixins: [FranchiseMixin],
  data() {
    return {
      stepsValidations: {},
      currentStep: "1",
      loading: false,
      isDataLoaded: false,
      franchiseLoading: false,
      isDoneButtonEnabled: false,
      franchiseSearch: null,
      enableSave: true,
      item: {},
      snackbar: {},
      franchiseItems: [],
      franchiseeId: "",
      stepperEnable: {
        1: true,
        2: false,
        3: false,
        4: false,
      },
      clusterLoading: false,
      clusterItems: [],
    };
  },
  computed: {
    ...mapGetters("auth", ["userRole"]),

    isUserAdmin() {
      return this.userRole?.includes("ADMIN");
    },
    crud_state() {
      return this.$route.query.crud_state;
    },
    blankContact() {
      return new Contact();
    },
    isSizeSmall() {
      if (this.$vuetify.breakpoint.width < 800) return true;
      else return false;
    },
  },
  async created() {
    if (this.id) {
      this.item = await this.get_item(this.id);
      this.item.franchise_id = this.item.franchise.id;
      this.franchiseItems = this.getDefaultFranchiseOptions();
      this.franchiseeId = this.id;
    } else if (this.$route.query.franchiseeSettings) {
      await this.editFranchiseSettings();
    } else {
      this.item = new Franchisees();
      this.item.status = true;
    }
    this.item.user = this.item.user ?? {};
    this.item.user.user_role = this.item.user.user_role ?? 100;
    this.isDataLoaded = true;
    this.getClusterSublookup(this.item.franchise);
    if (this.isUserAdmin) {
      this.stepperEnable = {
        1: true,
        2: true,
        3: true,
        4: true,
      };
    }
  },
  methods: {
    ...mapMutations("franchisee", ["setFranchiseeData"]),

    situationsLoaded() {
      this.currentStep = "4";
    },
    async getClusterSublookup(franchise = {}) {
      let service = new BaseService("sub-lookups");
      let res = null;

      let slectedFranchise = franchise.id
        ? franchise
        : this.franchiseItems.find(
            (item) => item.id === this.item.franchise_id
          );

      if (slectedFranchise.cluster_group_id) {
        this.clusterLoading = true;
        try {
          res = await service.get_all({
            lookup_id: slectedFranchise.cluster_group_id,
          });
        } catch (e) {
          // nothing here
        } finally {
          this.clusterLoading = false;
        }
        this.clusterItems = res.data.results;
      } else {
        this.item.cluster_group_sub_lookup_id = "";
        this.clusterItems = [];
      }
    },
    enableDone(event) {
      this.isDoneButtonEnabled = event;
    },
    disabledSave(event) {
      this.enableSave = event;
    },
    async get_item(id) {
      this.loading = true;
      let service = new BaseService("franchisees");
      let res = await service.get_one(id);
      this.loading = false;

      return res.data;
    },
    async editFranchiseSettings() {
      let franchiseId = this.$route.query.id;
      this.item = await this.get_item(franchiseId);
      this.item.franchise_id = this.item.franchise.id;
      this.franchiseItems = this.getDefaultFranchiseOptions();
      this.franchiseeId = franchiseId;
    },
    navigateToNextStep() {
      let stepNo = parseInt(this.currentStep);
      stepNo += 1;
      this.currentStep = `${stepNo}`;
      this.stepperEnable[stepNo] = true;
    },
    navigateToPreviousStep() {
      let stepNo = parseInt(this.currentStep);
      stepNo -= 1;
      this.currentStep = `${stepNo}`;
    },
    backToFranchiseesList() {
      if (this.$route.query.franchiseeSettings) {
        this.$router.push(`/user`);
      } else if (this.isCoverageFranchisee) {
        this.$router.push("/mod");
      } else {
        this.currentStep = "1";
        this.$router.push("/franchisees/list");
      }
    },
    validate() {
      this.$refs[`basicInfo`].validate();
      this.$refs["contact_form"].$refs[`contact-form`].validate();
      this.$refs["billing_form"].$refs[`contact-form`].validate();

      this.$nextTick(() => {
        let notValidated = Object.keys(this.stepsValidations).filter((key) => {
          return this.stepsValidations[key] === false;
        });

        if (notValidated.length > 0) {
          this.snackbar = {
            show: true,
            text: `Please Fill All the required Fields in the steps`,
            color: `red`,
          };
          this.resetSnackbar();
        } else this.saveFranchise();
      });
    },
    submitCurrentStep() {
      let validated;
      switch (this.currentStep) {
        case "1":
          this.stepsValidations.basicInfo = false;
          validated = this.$refs.basicInfo.validate();
          if (validated) {
            this.stepsValidations.basicInfo = true;
            this.navigateToNextStep();
          }
          break;
        case "2":
          this.stepsValidations.contact = false;
          validated = this.$refs["contact_form"].$refs[
            `contact-form`
          ].validate();
          if (validated) {
            this.stepsValidations.contact = true;
            this.navigateToNextStep();
          }
          break;
      }
    },
    resetSnackbar() {
      setTimeout(() => {
        this.snackbar = {};
      }, 2000);
    },
    prepareJsonForSaving(json) {
      const allContactTypes = [`contact`, `billing_contact`];
      allContactTypes.map((contact) => {
        let contactType = json[contact].address;
        if (contactType) {
          Object.keys(contactType).forEach((key) => {
            if (
              contactType[key] !== null &&
              typeof contactType[key] == "object"
            ) {
              let length = Object.keys(contactType[key]).length;
              if (length < 1) {
                contactType[key] = null;
              } else {
                let id = contactType[key].id;
                let oldKey = key;
                let newKey = key + "_id";
                contactType[newKey] = id;
                delete contactType[oldKey];
              }
            }
          });
        }
      });

      // Clearning all the other contact info
      allContactTypes.map((contact) => {
        let contactType = json[contact];
        if (contactType) {
          Object.keys(contactType).forEach((key) => {
            if (
              contactType[key] !== null &&
              typeof contactType[key] == "object"
            ) {
              let length = Object.keys(contactType[key]).length;
              if (length < 1) {
                contactType[key] = null;
              }
            }
          });
        }
      });

      // json.user = this.$store.state.auth.user.id;

      return json;
    },
    moveForward() {
      if (this.$route.query.franchiseeSettings) {
        this.$router.push("/mod");
      } else if (this.isCoverageFranchisee) {
        bus.$emit("activate_sideMenu", 2);
        this.$router.push(`/franchisee/customise-insurance/insurance-coverage`);
      } else {
        this.$router.push(`/franchisees/list`);
      }
    },
    async saveFranchise() {
      this.loading = true;
      let service = new BaseService("franchisees");
      let res = null;
      let franchiseeJson = _.cloneDeep(this.item);
      franchiseeJson = this.prepareJsonForSaving(franchiseeJson);
      // franchiseeJson.user = { ...this.$store.state.auth.user };

      if (this.crud_state == crud_state.NEW) {
        res = await service.create(franchiseeJson);
      } else {
        res = await service.update(franchiseeJson);
      }
      this.loading = false;

      if (res.status == 401) {
        this.handleLogout();
      } else if (res.status == 200 || res.status == 201) {
        this.setFranchiseeData(res.data);
        this.franchiseeId = res.data.id.toString();
        bus.$emit("edit_save", { msg: "Saved successfully", data: res.data });
        this.navigateToNextStep();
      } else {
        bus.$emit("edit_error", { msg: "An error occurred, could not save" });
      }
    },
    prepareJsonForExistingFranchise() {
      if (this.item) {
        const allContactTypes = [`contact`, `billing_contact`];
        allContactTypes.map((contact) => {
          this.item[contact].phone = this.item[contact].phone ?? {};
          this.item[contact].mobile = this.item[contact].mobile ?? {};
          this.item[contact].fax = this.item[contact].fax ?? {};

          let contactType = this.item[contact]?.address;
          if (contactType) {
            Object.keys(contactType).forEach((key) => {
              if (
                contactType[key] === null &&
                (key == "country" || key == "state" || key == "city")
              ) {
                contactType[key] = {};
              }
            });
          } else {
            this.item[contact] = this.blankContact;
          }
        });
      }
    },
    async getAllFranchises() {
      let service = new BaseService("franchises");
      this.franchiseLoading = true;
      let res = null;
      try {
        res = await service.get_all();
      } catch (e) {
        // nothing here
      } finally {
        this.franchiseLoading = false;
      }
      this.franchiseItems = res.data.results;
    },
    async getFranchiseItems(v) {
      this.franchiseLoading = true;
      let res = null;
      try {
        res = await franchiseService.get_name_suggestions(v);
      } catch (e) {
        // nothing here
      } finally {
        this.franchiseLoading = false;
      }
      return res.data.results;
    },
    getDefaultFranchiseOptions() {
      return this.item.franchise && this.item.franchise.id
        ? [{ id: this.item.franchise.id, name: this.item.franchise.name }]
        : [];
    },
  },
  watch: {
    item: {
      handler() {
        if (this.id) {
          this.prepareJsonForExistingFranchise();
          this.franchiseItems = this.franchiseItems = this.getDefaultFranchiseOptions();
        }
      },
      deep: true,
    },
    franchiseSearch: debounce(async function(val) {
      let franchiseName = this.item.franchise ? this.item.franchise.name : "";
      if (val && val !== franchiseName) {
        try {
          this.franchiseItems = await this.getFranchiseItems(val);
        } catch (e) {
          // console.log(e);
        }
      }
    }, 500),
  },
};
</script>
<style>
.v-stepper__step {
  padding: 24px 12px;
}
.v-stepper__header {
  flex-wrap: unset;
  justify-content: space-around;
}
@media screen and (max-width: 400px) {
  .v-stepper__step {
    padding: 0px !important;
  }
}
</style>
