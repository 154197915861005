import axios from "axios";
import authHeader from "./auth-header";
import BaseService from "./base.service";

class FranchiseService extends BaseService {
  constructor(url) {
    super(url);
  }

  get_name_suggestions(name) {
    let filters = { name: name };
    return axios.get(this.url + `/suggestions`, {
      headers: authHeader(this.token),
      params: filters,
    });
  }

  getFranchisesSuggestions(filters) {
    return axios.get(this.url + `/suggestions`, {
      headers: authHeader(this.token),
      params: filters,
    });
  }
}

export default new FranchiseService("franchises");
