<template>
  <div>
    <h1 class="page-title">Situations Manager</h1>

    <template>
      <SimpleTable
        :headers="headers"
        :items="items"
        :loading="loading"
        :searchLoading="searchLoading"
        :expanded_headers="expanded_headers"
        :totalCount="totalCount"
        url_fragment="situations"
        @hitPagination="fetchNextData"
        @hitSearch="fetchSearchedData"
        @add_item_click="addItemClick"
        addBtnTxt="Add Situation"
        @edit_item_click="editItemClick"
        :disableAdd="!franchiseeId || loading"
    /></template>

    <div v-if="franchiseeId">
      <v-dialog v-model="showEditModal" max-width="750px">
        <v-card v-if="loading">
          <v-skeleton-loader
            v-bind="$attrs"
            type="table-heading, list-item-two-line, image"
          ></v-skeleton-loader>
        </v-card>
        <v-card v-else>
          <v-card-title>{{ edit_form_dialog_title }}</v-card-title>
          <SituationEdit
            v-if="showEditModal"
            :crud_state="crud_state"
            :item="itemToEdit"
            :franchiseeId="franchiseeId"
            @edit_save="editSave"
            @edit_error="editError"
            @error="error"
          />
          <v-card-actions>
            <v-btn color="primary" text @click="showEditModal = false"
              >Cancel</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import SimpleTable from "@/components/common/SimpleTable";
import { crud_state } from "@/lib/constants";
import BaseService from "@/services/base.service.js";
import FranchiseesService from "@/services/franchisees.service.js";
import SituationEdit from "./SituationEdit";
import Address from "@/models/address-form";
import { mapGetters } from "vuex";

export default {
  name: "SituationsList",
  components: {
    SimpleTable,
    SituationEdit,
  },
  props: {
    franchiseeId: String,
  },
  data() {
    return {
      headers: [
        {
          text: "Situation Name",
          value: "name",
        },
        {
          text: "Trading Name",
          value: "trading_name",
        },
        {
          text: "Status",
          value: "status",
        },
        {
          text: "Franchisee",
          value: "franchisee.name",
        },
        {
          text: "Code",
          value: "code",
        },
        { text: "Actions", value: "actions", sortable: false },
        { text: "", value: "data-table-expand" },
      ],
      expanded_headers: [
        { text: "Interested Parties", value: "interested_parties" },
        { text: "Entity Type", value: "entity_type" },
      ],
      loading: false,
      showEditModal: false,
      searchLoading: false,
      crud_state: crud_state.LIST,
      items: [],
      totalCount: 0,
      itemToEdit: null,
      limit: 10,
      offset: 0,
      search: "",
      paginations: {},
    };
  },
  computed: {
    ...mapGetters("auth", ["userRole"]),

    isUserAdmin() {
      return this.userRole.includes("ADMIN");
    },
    edit_form_dialog_title() {
      return this.crud_state === crud_state.ADD
        ? `Add Situation`
        : `Edit Situation`;
    },
  },
  async created() {
    this.$emit("isSituationPresent", false);
    if (this.$route.query.franchiseeSettings) this.$emit("itemsLoaded");
    if (this.franchiseeId) {
      this.items = await this.getItems({});
    }

    this.$emit("isSituationPresent", !!this.items.length);

    if (this.isUserAdmin) {
      this.headers.splice(
        5,
        0,
        {
          text: "Insurance Status",
          value: "insurance_status.name",
        },
        {
          text: "Situation Type",
          value: "situation_type.name",
        }
      );

      this.expanded_headers.splice(
        2,
        0,
        { text: "Is Secure Location?", value: "is_secure_loc" },
        { text: "Notes", value: "notes" }
      );
    }
  },
  methods: {
    async fetchNextData(pagination) {
      this.items = await this.getItems({ pagination });
    },
    async fetchSearchedData(search) {
      if (search) {
        const limit = this.paginations.itemsPerPage;
        const offset =
          (this.paginations.page - 1) * this.paginations.itemsPerPage + 1;

        this.searchLoading = true;
        let service = new BaseService("situations/suggestions");
        let filters = { limit: limit, start: offset, search };

        try {
          let res = await service.get_all(filters);
          this.totalCount = res.data.count;
          this.items = res.data.results;
        } catch (e) {
          console.error(e);
        } finally {
          this.searchLoading = false;
        }
      } else {
        this.items = await this.get_items(this.paginations);
      }
    },
    async getItems({ pagination = { itemsPerPage: 10, page: 1 } }) {
      this.paginations = { ...pagination };
      const limit = pagination.itemsPerPage;
      const offset = (pagination.page - 1) * pagination.itemsPerPage + 1;

      this.loading = true;
      let service = new BaseService("situations");
      let filters = { limit: limit, start: offset };
      let res;

      try {
        if (this.franchiseeId) {
          res = await FranchiseesService.getSituationsByFranchisee(
            this.franchiseeId,
            filters
          );
        } else {
          res = await service.get_all(filters);
        }

        this.totalCount = res.data.count;
        return res.data.results;
      } catch (e) {
        if (e.response.status === 401) {
          this.$store.dispatch("auth/logout");
        }
        console.error(e);
      } finally {
        this.loading = false;
        this.$emit("isSituationPresent", !!this.items.length);
      }
    },
    async getItem(id) {
      if (!id) {
        return null;
      }
      this.loading = true;
      let service = new BaseService("situations");
      let res = await service.get_one(id);

      // res.data.key_contact = res.data.key_contact ?? new Contact();
      // res.data.key_contact.contact_id = res.data.key_contact?.id;
      // res.data.key_contact.phone = res.data.key_contact.phone ?? {};
      // res.data.key_contact.fax = res.data.key_contact.fax ?? {};
      // res.data.key_contact.mobile = res.data.key_contact.mobile ?? {};
      // res.data.key_contact.address =
      //   res.data.key_contact.address ?? new Address();
      this.loading = false;
      return res.data;
    },
    addItemClick() {
      this.crud_state = crud_state.ADD;
      this.itemToEdit = {
        franchisee_id: this.franchiseeId,
        address: new Address(),
      };
      this.showEditModal = true;
    },
    async editItemClick(id) {
      this.logout_if_login_not_valid();
      // pop up edit form
      // this.crud_id = id;
      this.$emit("hideSave", false);
      this.itemToEdit = await this.getItem(id);
      if (this.itemToEdit) {
        this.itemToEdit.address = this.itemToEdit.address ?? new Address();
        this.itemToEdit.franchise_id =
          this.itemToEdit.franchisee_id ?? this.franchiseeId;
        this.crud_state = crud_state.EDIT;
        this.showEditModal = true;
      } else {
        this.edit_error();
      }
    },
    async editSave(/* item */) {
      this.$store.dispatch("common/setFlashMsg", "Saved successfully");
      // refresh data
      this.items = await this.getItems({ type_id: this.type_id });
      this.showEditModal = false;
      this.$emit("isSituationPresent", !!this.items.length);
    },
    editError(payload) {
      this.showEditModal = false;
      this.$store.dispatch("common/setFlashMsg", payload.msg);
    },
    error(payload) {
      this.showEditModal = false;
      this.$store.dispatch("common/setFlashMsg", payload.msg);
    },
  },
  watch: {
    showEditModal() {
      this.$emit("hideSave", !this.showEditModal);
    },
  },
};
</script>
