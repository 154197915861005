<template>
  <div>
    <h1 class="page-title">Users Manager</h1>

    <SimpleTable
      :headers="headers"
      :items="items"
      :loading="loading"
      :searchLoading="searchLoading"
      :totalCount="totalCount"
      :expanded_headers="expanded_headers"
      url_fragment="users"
      :disableAdd="!userHasPermission"
      :hideDelIcon="!userHasPermission"
      :hideEditIcon="!userHasPermission"
      @hitPagination="fetchNextData"
      @hitSearch="fetchSearchedData"
      @add_item_click="add_item_click"
      @edit_item_click="edit_item_click"
    />
    <Snackbar
      class="mb-10"
      v-if="snackbar.show"
      :show="snackbar.show"
      :text="snackbar.text"
      :color="snackbar.color"
    ></Snackbar>
  </div>
</template>

<script>
import SimpleTable from "@/components/common/SimpleTable";
import Snackbar from "@/components/common/Snackbar";
import { crud_state } from "@/lib/constants";
import BaseService from "@/services/base.service.js";
import { mapGetters } from "vuex";
import { bus } from "@/main.js";

export default {
  name: "UsersList",
  components: {
    SimpleTable,
    Snackbar,
  },
  data() {
    return {
      headers: [
        {
          text: "Business Name",
          value: "name",
        },
        {
          text: "Email",
          value: "email",
        },
        {
          text: "User Role Name",
          value: "user_role_name",
        },
        {
          text: "Status",
          value: "status",
        },
        {
          text: "Actions",
          value: "actions",
        },
        { text: "", value: "data-table-expand" },
      ],
      expanded_headers: [
        { text: "ID", value: "id" },
        { text: "User Role", value: "user_role" },
      ],
      loading: false,
      searchLoading: false,
      crud_state: crud_state.LIST,
      items: [],
      totalCount: 0,
      item_to_edit: null,
      limit: 10,
      offset: 0,
      search: "",
      paginations: {},
      snackbar: {},
    };
  },
  async mounted() {
    this.items = await this.getItems({});

    bus.$on("edit_save", async () => {
      this.$store.dispatch("common/setFlashMsg", "Saved successfully");
    });
  },
  computed: {
    ...mapGetters("auth", ["userRole"]),

    userHasPermission() {
      return this.userRole.includes("ADMIN");
    },
  },
  methods: {
    async fetchNextData(pagination) {
      this.items = await this.getItems({ pagination });
    },
    async fetchSearchedData(search) {
      if (search) {
        const limit = this.paginations.itemsPerPage;
        const offset =
          (this.paginations.page - 1) * this.paginations.itemsPerPage + 1;

        this.searchLoading = true;
        let service = new BaseService("users/suggestions");
        let filters = { limit: limit, start: offset, search };

        try {
          let res = await service.get_all(filters);
          this.totalCount = res.data.count;
          this.items = res.data.results;
        } catch (e) {
          console.error(e);
        } finally {
          this.searchLoading = false;
        }
      } else {
        this.items = await this.getItems(this.paginations);
      }
    },
    async getItems({ pagination = { itemsPerPage: 10, page: 1 } }) {
      this.paginations = { ...pagination };
      const limit = pagination.itemsPerPage;
      const offset = (pagination.page - 1) * pagination.itemsPerPage + 1;

      this.loading = true;
      let service = new BaseService("users");
      let filters = { limit: limit, start: offset };

      try {
        let res = await service.get_all(filters);
        this.totalCount = res.data.count;
        return res.data.results;
      } catch (e) {
        if (e.response.status === 401) {
          this.$store.dispatch("auth/logout");
        }
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
    add_item_click() {
      this.crud_state = crud_state.NEW;
      this.$router.push({
        path: "/users/add",
        query: {
          crud_state: this.crud_state,
        },
      });
    },
    edit_item_click(id) {
      if (id) {
        this.crud_state = crud_state.EDIT;
        this.$router.push({
          path: `/users/edit/${id}`,
          query: {
            crud_state: this.crud_state,
          },
        });
      } else {
        this.edit_error("Invalid data passed");
      }
    },
  },
};
</script>
