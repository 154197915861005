<template>
  <div>
    <v-card v-if="item">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-container fluid>
          <v-row>
            <v-col>
              <v-text-field
                v-model="item.name"
                :rules="[(v) => !!v || 'Situation Company Name is required']"
                required
              >
                <template #label>
                  Situation Company Name<span class="red--text"
                    ><strong> * </strong></span
                  >
                </template></v-text-field
              >
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-switch
                v-model="item.status"
                :disabled="!isUserAdmin"
                inset
                label="Status"
              ></v-switch>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model="item.franchisee_id"
                required
                :disabled="isFranchiseeDisabled"
                :rules="[(v) => !!v || 'Franchisee Id is required']"
              >
                <template #label>
                  Franchisee Id<span class="red--text"
                    ><strong> * </strong></span
                  >
                </template></v-text-field
              >
            </v-col>
          </v-row>
          <!-- <v-row>
            <v-col>
              <v-text-field
                v-model="item.code"
                :disabled="!isUserAdmin"
                label="Code"
              ></v-text-field>
            </v-col>
          </v-row> -->
          <v-row v-if="isUserAdmin">
            <v-col>
              <v-select
                :items="insuranceStatuses"
                v-model="item.insurance_status_lookup_id"
                item-text="name"
                item-value="id"
                dense
                :rules="[(v) => !!v || 'Insturance Status is required']"
                required
                ><template #label>
                  Select Insurance Status<span class="red--text"
                    ><strong> * </strong></span
                  >
                </template>
              </v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-select
                :items="entityTypes"
                label="Entity Type"
                v-model="item.entity_type"
                item-text="name"
                item-value="value"
                dense
              >
              </v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model="item.trading_name"
                :rules="[(v) => !!v || 'Trading Name is required']"
                required
                ><template #label>
                  Trading Name<span class="red--text"
                    ><strong> * </strong></span
                  >
                </template></v-text-field
              >
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field v-model="item.abn"
                ><template #label>
                  ABN
                </template></v-text-field
              >
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <!-- <AddressAutocompleteForm
                class="mb-5"
                :form.sync="item.address"
                :type="`business`"
                title="Address"
              /> -->
              <AddressForm
                class="mb-5"
                :form="item.address ? item.address : blankAddress"
                :type="`business`"
                :title="'Address'"
                isStateRequired
                isPostCodeRequired
                isCountryRequired
                isCityRequired
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model="item.interested_parties"
                label="Interested Parties"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row v-if="isUserAdmin">
            <v-col>
              <v-switch
                v-model="item.is_secure_loc"
                inset
                label="Is Secure Location"
              ></v-switch>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-select
                :items="situationTypes"
                v-model="item.type_lookup_id"
                item-text="name"
                item-value="id"
                dense
                :rules="[(v) => !!v || 'Situation Type is required']"
                required
                ><template #label>
                  Select Situation Type<span class="red--text"
                    ><strong> * </strong></span
                  >
                </template></v-select
              >
            </v-col>
          </v-row>
          <v-row v-if="isUserAdmin">
            <v-col>
              <v-text-field v-model="item.notes" label="Notes"></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-btn
                class="ma-2"
                :loading="loading"
                :disabled="loading"
                color="secondary"
                @click="validate"
              >
                <v-icon small left>mdi-check</v-icon>
                Save
              </v-btn>
            </v-col>
            <v-col>
              <v-btn class="ma-2" outlined @click="reset">
                Reset Form
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import { crud_state } from "@/lib/constants";
import BaseService from "@/services/base.service.js";
// import AddressAutocompleteForm from "@/components/common/address-form/AddressAutoCompleteForm";
import AddressForm from "@/components/common/address-form/AddressForm";
import Address from "@/models/address-form";
import { mapGetters } from "vuex";

export default {
  name: "SituationEdit",

  props: ["crud_state", "item", "franchiseeId"],

  components: { AddressForm },

  data() {
    return {
      loading: false,
      valid: true,
      isFranchiseeDisabled: false,
      insuranceStatuses: [
        { name: "Pending", id: 201 },
        { name: "Insured", id: 202 },
        { name: "New", id: 203 },
      ],
      situationTypes: [
        { name: "Shop", id: 204 },
        { name: "Office", id: 205 },
        { name: "Workshop", id: 206 },
        { name: "Warehouse", id: 207 },
        { name: "Cafe", id: 208 },
        { name: "Restaurant", id: 209 },
        { name: "Kiosk", id: 210 },
      ],
      entityTypes: [
        { name: "Sole Trader", value: "sole trader", id: 1 },
        { name: "Partnership", value: "partnership", id: 2 },
        { name: "Company", value: "company", id: 3 },
      ],
    };
  },

  computed: {
    ...mapGetters("auth", ["userRole"]),

    isUserAdmin() {
      return this.userRole.includes("ADMIN");
    },
    blankAddress() {
      return new Address();
    },
  },

  created() {
    if (this.franchiseeId) {
      this.isFranchiseeDisabled = true;
    }

    if (!this.isUserAdmin && this.crud_state == crud_state.ADD) {
      this.item.insurance_status_lookup_id = 202;
    }
  },

  methods: {
    validate() {
      let result = this.$refs.form.validate();
      if (result) {
        this.save();
      }
    },
    reset() {
      this.$refs.form.reset();
    },
    async save() {
      this.loading = true;
      let service = new BaseService("situations");
      // this.item.address_id = this.item.address?.address_id;
      this.item.address.country_id = this.item.address?.country?.id;
      this.item.address.state_id = this.item.address?.state?.id;
      this.item.address.city_id = this.item.address?.city?.id;

      let res;
      if (this.crud_state == crud_state.ADD) {
        res = await service.create(this.item);
      } else {
        res = await service.update(this.item);
      }
      this.loading = false;

      if (res.status == 401) {
        this.handleLogout();
      } else if (res.status == 200 || res.status == 201) {
        this.$emit("edit_save", {
          msg: "Saved successfully",
          data: res.data.results,
        });
        // this.item = res.data.results;
      } else {
        this.$emit("edit_error", { msg: "An error occurred, could not save" });
      }
    },

    handleLogout() {
      this.$store.dispatch("auth/logout");
    },
  },
};
</script>
