<template>
  <div>
    <h1 class="page-title" :style="titleColor">Client Manager</h1>

    <SimpleTable
      :headers="headers"
      :items="items"
      :loading="loading"
      :searchLoading="searchLoading"
      :expanded_headers="expanded_headers"
      :totalCount="totalCount"
      :showEmailIcon="isUserAdmin"
      url_fragment="franchisees"
      @sendEmail="showEmailWarning"
      @hitPagination="fetchNextData"
      @hitSearch="fetchSearchedData"
      @add_item_click="add_item_click"
      @edit_item_click="edit_item_click"
    />

    <v-dialog v-model="showMessageModal" max-width="750px">
      <v-card>
        <v-card-title>Confirm Action</v-card-title>
        <v-divider></v-divider>
        <div class="space-20"></div>
        <v-card-text>
          {{ emailMessage }}
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="primary"
            text
            @click="closeMessageModal"
            v-if="emailSent"
            >Close</v-btn
          >
          <div v-else>
            <v-btn color="success" text @click="sendEmail()">
              <v-icon small left>mdi-check</v-icon>
              Send Email</v-btn
            >
            <v-btn color="error" text @click="showMessageModal = false"
              >Cancel</v-btn
            >
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SimpleTable from "@/components/common/SimpleTable";
import { crud_state } from "@/lib/constants";
import BaseService from "@/services/base.service.js";
import FranchiseesService from "@/services/franchisees.service.js";
import FranchiseMixin from "@/mixins/franchise-style";
// import { bus } from "@/main.js";

export default {
  name: "FranchiseesList",
  components: {
    SimpleTable,
  },
  data() {
    return {
      headers: [
        {
          text: "Legal Entity Name",
          value: "name",
        },
        {
          text: "Trading Name",
          value: "trading_name",
        },
        {
          text: "Key person",
          value: "contact.person_name",
        },
        {
          text: "Billing person",
          value: "billing_contact.person_name",
        },
        {
          text: "Franchise/Scheme",
          value: "franchise.name",
        },
        {
          text: "Status",
          value: "status",
        },
        {
          text: "Actions",
          value: "actions",
        },
        { text: "", value: "data-table-expand" },
      ],
      expanded_headers: [
        { text: "ABN Account", value: "abn_acn" },
        { text: "Always Refer", value: "always_refer" },
        { text: "Force New Policy Set", value: "force_new_policy_set" },
      ],
      loading: false,
      crud_state: crud_state.LIST,
      items: [],
      totalCount: 0,
      item_to_edit: null,
      limit: 10,
      offset: 0,
      search: "",
      paginations: {},
      showMessageModal: false,
      searchLoading: false,
      emailEntityObj: {},
      emailMessage: "",
      emailSent: false,
    };
  },
  mixins: [FranchiseMixin],
  async mounted() {
    this.items = await this.get_items({});
  },
  computed: {
    ...mapGetters("auth", ["userRole"]),

    isUserAdmin() {
      return this.userRole.includes("ADMIN");
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  methods: {
    async fetchNextData(pagination) {
      this.items = await this.get_items({ pagination });
    },
    async fetchSearchedData(search) {
      if (search) {
        const limit = this.paginations.itemsPerPage;
        const offset =
          (this.paginations.page - 1) * this.paginations.itemsPerPage + 1;

        this.searchLoading = true;
        let filters = { limit: limit, start: offset, search };

        try {
          let res = await FranchiseesService.getFranchiseesSuggestions(filters);
          this.totalCount = res.data.count;
          this.items = res.data.results;
        } catch (e) {
          console.error(e);
        } finally {
          this.searchLoading = false;
        }
      } else {
        this.items = await this.get_items(this.paginations);
      }
    },
    async get_items({ pagination = { itemsPerPage: 10, page: 1 } }) {
      this.paginations = { ...pagination };
      const limit = pagination.itemsPerPage;
      const offset = (pagination.page - 1) * pagination.itemsPerPage + 1;

      this.loading = true;
      let service = new BaseService("franchisees");
      let filters;
      if (this.isFranchisor) {
        filters = {
          limit: limit,
          start: offset,
          franchise_id: this.franchiseData.id,
        };
      } else {
        filters = { limit: limit, start: offset };
      }

      try {
        let res = await service.get_all(filters);
        this.totalCount = res.data.count;
        return res.data.results;
      } catch (e) {
        if (e.response.status === 401) {
          this.$store.dispatch("auth/logout");
        }
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
    closeMessageModal() {
      this.showMessageModal = false;
      this.emailSent = false;
    },
    showEmailWarning(franchise) {
      this.emailEntityObj = franchise;
      this.emailMessage = `Are you sure you want to email ${this.emailEntityObj.name}?`;
      this.showMessageModal = true;
    },
    async sendEmail() {
      let service = new BaseService("franchisees/invitations");
      let filters = {
        entity_id: this.emailEntityObj.id,
        invitation_type: "franchisee",
      };

      try {
        let res = await service.create(filters);

        this.emailSent = true;
        if (res.data?.success) {
          this.emailMessage = `Mail sent successfully!`;
        } else {
          this.emailMessage = `Error Sending mail. Please try again later!`;
        }
      } catch (e) {
        if (e.response.status === 401) {
          this.$store.dispatch("auth/logout");
        }
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
    add_item_click() {
      this.crud_state = crud_state.NEW;
      this.$router.push({
        path: "/franchisees/add",
        query: {
          crud_state: this.crud_state,
        },
      });
    },
    edit_item_click(id) {
      if (id) {
        this.crud_state = crud_state.EDIT;
        this.$router.push({
          path: `/franchisees/edit/${id}`,
          query: {
            crud_state: this.crud_state,
          },
        });
      } else {
        this.edit_error("Invalid data passed");
      }
    },
  },
};
</script>
