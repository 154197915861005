<template>
  <div>
    <h1 class="page-title">Policies Manager</h1>

    <template>
      <SimpleTable
        :headers="headers"
        :items="items"
        :loading="loading"
        :expanded_headers="expanded_headers"
        :totalCount="totalCount"
        :showDownloadIcon="true"
        disableAdd
        url_fragment="policies"
        @hitPagination="fetchNextData"
        @edit_item_click="edit_item_click"
        @downloadItem="downloadPolicy"
    /></template>

    <v-dialog v-model="showEditDialog" persistent max-width="800">
      <v-card>
        <v-card-title class="text-h5">
          Edit Policy
        </v-card-title>
        <v-card v-if="loadingEdit">
          <v-skeleton-loader
            v-bind="$attrs"
            type="table-heading, list-item-two-line, image"
          ></v-skeleton-loader>
        </v-card>
        <v-card-text v-else>
          <v-row>
            <v-col cols="12" lg="6" md="6" class="col-x-margin-0">
              <p>Franchisee Name</p>
              <v-text-field
                disabled
                v-model="editItem.franchiseeName"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="6" md="6" class="col-x-margin-0">
              <p>Franchise Name</p>
              <v-text-field
                disabled
                v-model="editItem.franchiseName"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="col-x-margin-0">
              <p>Policy Status</p>
              <v-select
                :items="policyStatusItems"
                v-model="editItem.status"
                item-text="name"
                item-value="id"
                dense
              >
              </v-select>
            </v-col>
            <v-col cols="12" class="col-x-margin-0">
              <p>Address</p>
              <v-text-field
                disabled
                v-model="editItem.address"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="6" md="6" class="col-x-margin-0">
              <p>Start Date</p>
              <v-text-field
                disabled
                v-model="editItem.start_dt"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="6" md="6" class="col-x-margin-0">
              <p>Expiry Date</p>
              <v-text-field
                disabled
                v-model="editItem.expiry_dt"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="6" md="6" class="col-x-margin-0">
              <p>Amount</p>
              <v-text-field
                disabled
                v-model="editItem.amount"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="6" md="6" class="col-x-margin-0">
              <p>Policy Type</p>
              <v-text-field
                disabled
                v-model="editItem.policyType"
                dense
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="savePolicy">
            Save
          </v-btn>
          <v-btn color="danger" text @click="showEditDialog = false">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <Snackbar
      class="mb-10"
      v-if="snackbar.show"
      :show="snackbar.show"
      :text="snackbar.text"
      :color="snackbar.color"
    ></Snackbar>
    <iframe :src="policyUrl" v-show="false"></iframe>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SimpleTable from "@/components/common/SimpleTable";
import BaseService from "@/services/base.service.js";
import Snackbar from "@/components/common/Snackbar";
import PolicyService from "@/services/policy.service.js";
import axios from "axios";
import authHeader from "@/services/auth-header";

export default {
  name: "PoliciesList",

  components: {
    SimpleTable,
    Snackbar,
  },

  props: {
    filterType: String,
  },

  data() {
    return {
      headers: [
        {
          text: "Franchisee name",
          value:
            "policy_template_product_situation[0].situation.franchisee.name",
        },
        {
          text: "Situation address",
          value: "address",
        },
        {
          text: "Franchise name",
          value:
            "policy_template_product_situation[0].template_product_map.template.franchise.name",
        },
        {
          text: "Policy status",
          value: "policyStatus",
        },
        { text: "", value: "data-table-expand" },
      ],
      expanded_headers: [
        { text: "Start Date", value: "start_dt" },
        { text: "Expiry Date", value: "expiry_dt" },
        { text: "Amount", value: "amount" },
        { text: "Policy Type", value: "policyType" },
      ],
      loading: false,
      loadingEdit: false,
      items: [],
      totalCount: 0,
      search: "",
      paginations: {},
      policyTypesLoopup: { 1: "new", 2: "renewal", 3: "end" },
      policyStatusLoopup: {
        0: "inactive",
        1: "active",
        2: "quote",
        3: "pending_confirmation",
        4: "expired",
      },
      policyStatusItems: [
        {
          id: 0,
          name: "inactive",
        },
        {
          id: 1,
          name: "active",
        },
        {
          id: 2,
          name: "quote",
        },
        {
          id: 3,
          name: "pending_confirmation",
        },
        {
          id: 4,
          name: "expired",
        },
      ],
      showEditDialog: false,
      editId: "",
      editItem: {},
      snackbar: {},
      policyUrl: "",
    };
  },

  computed: {
    ...mapGetters("auth", ["userRole"]),

    isUserAdmin() {
      return this.userRole.includes("ADMIN");
    },
  },

  watch: {
    filterType() {
      this.getItems({});
    },
  },

  async mounted() {
    this.getItems({});

    if (this.isUserAdmin) {
      this.headers.splice(4, 0, {
        text: "Actions",
        value: "actions",
        sortable: false,
      });
    }
  },

  methods: {
    async downloadPolicy(policy) {
      try {
        this.$store.dispatch(
          "common/setFlashMsg",
          "Preparing to download the policy schedule"
        );

        let res = await PolicyService.downloadPolicySchedule(policy.id);
        let res1 = await axios.get(res.data.url, {
          headers: authHeader(this.token),
        });

        this.policyUrl = res1.data.url;

        setTimeout(() => {
          this.$store.dispatch(
            "common/setFlashMsg",
            "Policy Schedule downloaded"
          );
        }, 3000);
      } catch (error) {
        this.$store.dispatch(
          "common/setFlashMsg",
          "Some error occured. Please try again later"
        );
      }
    },
    fetchNextData(pagination) {
      this.getItems({ pagination });
    },
    async fetchSearchedData(search) {
      if (search) {
        // const limit = this.paginations.itemsPerPage;
        // const offset =
        //   (this.paginations.page - 1) * this.paginations.itemsPerPage + 1;
        // this.loading = true;
        // let filters = { limit: limit, start: offset, search };
        // try {
        //   let res = await FranchiseesService.getFranchiseesSuggestions(filters);
        //   this.totalCount = res.data.count;
        //   this.items = res.data.results;
        // } catch (e) {
        //   console.error(e);
        // } finally {
        //   this.loading = false;
        // }
      } else {
        this.getItems(this.paginations);
      }
    },
    async getItems({ pagination = { itemsPerPage: 10, page: 1 } }) {
      this.paginations = { ...pagination };
      const limit = pagination.itemsPerPage;
      const offset = (pagination.page - 1) * pagination.itemsPerPage + 1;

      this.loading = true;
      let service = new BaseService("policies");

      let policyTypes = ["new"];
      let policyStatus = ["pending_confirmation", "active", "expired"];

      let filter = {};

      if (policyStatus.includes(this.filterType)) {
        filter = {
          policy_status: Object.keys(this.policyStatusLoopup)[
            Object.values(this.policyStatusLoopup).indexOf(this.filterType)
          ],
        };
      } else if (policyTypes.includes(this.filterType)) {
        filter = {
          policy_type: Object.keys(this.policyStatusLoopup)[
            Object.values(this.policyTypesLoopup).indexOf(this.filterType)
          ],
        };
      }

      let filters;
      filters = { limit: limit, start: offset, ...filter };

      try {
        let res = await service.get_all(filters);
        this.totalCount = res.data.count;
        this.items = res.data.results;
        this.items.forEach((item) => {
          item.address = `${item.policy_template_product_situation[0].situation.address.address1}, ${item.policy_template_product_situation[0].situation.address.address2}, ${item.policy_template_product_situation[0].situation.address.city.name}, ${item.policy_template_product_situation[0].situation.address.state.name}, ${item.policy_template_product_situation[0].situation.address.post_code}, ${item.policy_template_product_situation[0].situation.address.country.name}`;
          item.policyStatus = this.policyStatusLoopup[item.status];
          item.policyType = this.policyTypesLoopup[item.policy_type];
        });
      } catch (e) {
        if (e.response && e.response.status === 401) {
          this.$store.dispatch("auth/logout");
        }
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
    edit_item_click(id) {
      this.showEditDialog = true;
      this.editId = id;
      this.editItem = this.items.find((item) => item.id === id);
      this.editItem.franchiseeName = this.editItem.policy_template_product_situation[0].situation?.franchisee?.name;
      this.editItem.franchiseName = this.editItem.policy_template_product_situation[0].template_product_map?.template?.franchise?.name;
    },
    async savePolicy() {
      this.loadingEdit = true;
      let service = new BaseService("policies");
      let res = null;

      res = await service.patch({
        id: this.editItem.id,
        status: this.editItem.status,
      });

      this.loadingEdit = false;

      if (res.status == 401) {
        this.handleLogout();
      } else if (res.status == 200 || res.status == 201) {
        this.getItems({});
      } else {
        this.showSnackBar(res.data.message);
      }

      this.showEditDialog = false;
    },

    showSnackBar(errorMsg) {
      this.snackbar = {
        show: true,
        text: errorMsg ?? "An error occurred, Please try again",
        color: `red`,
      };
      this.resetSnackbar();
    },

    resetSnackbar() {
      setTimeout(() => {
        this.snackbar = {};
      }, 2000);
    },
  },
};
</script>
