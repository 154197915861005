<template>
  <div>
    <h2 v-if="title" class="mb-5">{{ title }}</h2>
    <v-row>
      <v-col cols="12" lg="6" md="6">
        <v-text-field
          label="Street Address 1"
          outlined
          v-model="form.address1"
          dense
          :rules="[(v) => !!v || 'Street Address 1 is required']"
          prepend-icon="mdi-card-account-details-outline"
        ></v-text-field>
      </v-col>
      <v-col cols="12" lg="6" md="6">
        <v-text-field
          label="Street Address 2"
          outlined
          v-model="form.address2"
          dense
          prepend-icon="mdi-card-account-details-outline"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" lg="6" md="6">
        <v-combobox
          :items="countries"
          @update:search-input="preparePlace($event, 'country', 'countries')"
          :search-input.sync="selectedPlace.country"
          hide-no-data
          @focus="getAllPlace('countries')"
          clearable
          v-model="form.country.name"
          @change="setInItem(`country`, form.country.name)"
          @click:clear="form.country = {}"
          item-text="name"
          class="margin-left-10"
          outlined
          dense
          autocomplete="nope"
          :rules="countryRule"
          ><template #label>
            Select Country<span v-if="isCountryRequired" class="red--text"
              ><strong> * </strong></span
            >
          </template>
        </v-combobox>
      </v-col>
      <v-col cols="12" lg="6" md="6">
        <v-combobox
          :items="states"
          @focus="getAllPlace('states')"
          @update:search-input="preparePlace($event, 'state', 'states')"
          :search-input.sync="selectedPlace.state"
          :disabled="!form.country.name"
          @change="setInItem(`state`, form.state.name)"
          @click:clear="form.state = {}"
          clearable
          v-model="form.state.name"
          item-text="name"
          class="margin-left-10"
          outlined
          dense
          autocomplete="nope"
          :rules="stateRule"
          ><template #label>
            Select State<span v-if="isStateRequired" class="red--text"
              ><strong> * </strong></span
            >
          </template>
        </v-combobox>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" lg="6" md="6">
        <!-- <v-combobox
          :items="cities"
          @focus="getAllPlace('cities')"
          @update:search-input="preparePlace($event, 'city', 'cities')"
          @click:clear="form.city = {}"
          :search-input.sync="selectedPlace.city"
          :disabled="!form.country.name"
          @change="setInItem(`city`, form.city.name)"
          clearable
          v-model="form.city.name"
          item-text="name"
          class="margin-left-10"
          outlined
          dense
          autocomplete="nope"
          :rules="cityRule"
          ><template #label>
            Select City<span v-if="isCityRequired" class="red--text"
              ><strong> * </strong></span
            >
          </template>
        </v-combobox> -->
        <v-text-field
          class="margin-left-10"
          label="Enter City/ Suburb Name"
          v-model="form.city_text"
          outlined
          :rules="[(v) => !!v || 'City/ Suburb is required']"
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="12" lg="6" md="6">
        <v-text-field
          v-model="form.post_code"
          type="number"
          class="margin-left-10"
          :rules="postCodeRule"
          outlined
          dense
          ><template #label>
            Enter Postcode<span v-if="isPostCodeRequired" class="red--text"
              ><strong> * </strong></span
            >
          </template></v-text-field
        >
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ContactService from "@/services/contact.service.js";
import { debounce } from "@/helpers/basic_helper";

export default {
  name: "AddressForm",
  props: {
    form: Object,
    type: String,
    title: String,
    isStateRequired: Boolean,
    isPostCodeRequired: Boolean,
    isCountryRequired: Boolean,
    isCityRequired: Boolean,
  },
  data() {
    return {
      selectedPlace: {},
      countries: [],
      states: [],
      cities: [],
      stateRule: [(v) => (!!v && this.isStateRequired) || "State is required"],
      postCodeRule: [
        (v) => (!!v && this.isStateRequired) || "Postcode is required",
      ],
      countryRule: [
        (v) => (!!v && this.isStateRequired) || "Country is required",
      ],
      cityRule: [
        (v) => (!!v && this.isStateRequired) || "City/ Suburb is required",
      ],
    };
  },
  methods: {
    setInItem(type, obj) {
      if (obj) {
        this.form[type].id = obj.id;
        this.form[type].name = obj.name;
      }
    },
    async getAllPlace(type) {
      let service = new ContactService(type);

      let params = {};
      if (type !== "countries") {
        params.country_id = this.form.country.id;
        if (type === "cities" && this.form.state.id) {
          params.state_id = this.form.state.id;
        }

        this.getPlace(type, params, type);
      } else {
        try {
          let res = await service.get_all(params);
          if (res) {
            this[type] = res.data.results;
          }
        } catch (error) {
          // console.log(error.response ? error.response : error);
        }
      }
    },
    getPlace: debounce(async function(type, params, arr) {
      let service = new ContactService(type);
      try {
        let res = await service.get_one(params);
        if (res) {
          this[arr] = res.data.results;
        }
      } catch (error) {
        // console.log(error.response ? error.response : error);
      }
    }, 500),
    preparePlace(event, type, arr) {
      let placeSearch = event;
      if (placeSearch) {
        placeSearch = placeSearch.replace(/\s/g, "");
        let params;
        switch (type) {
          case "country":
            params = { name: placeSearch };
            this.getPlace("countries", params, arr);
            break;
          case "state":
            params = {
              country_id: this.form.country.id,
              name: placeSearch,
            };
            this.getPlace("states", params, arr);
            break;
          case "city":
            params = {
              country_id: this.form.country.id,
              state_id: this.form.state.id,
              name: placeSearch,
            };
            this.getPlace("cities", params, arr);
            break;
        }
      }
    },
  },
};
</script>

<style scoped>
.margin-left-10 {
  margin-left: 2rem;
}
.v-messages,
.theme--light,
.v-messages__message {
  color: red !important;
}
</style>
