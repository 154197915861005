import Contact from "./contact-form";

export default class Franchisees {
  constructor() {
    (this.id = null), (this.descr = null);
    this.name = "";
    this.status = null;
    this.franchise_id = null;
    this.user_id = null;
    this.contact = new Contact();
    this.billing_contact = new Contact();
    this.client_code = null;
    this.force_new_policy_set = null;
    this.abn_acn = null;
    this.always_refer = null;
  }
}
